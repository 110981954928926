import { useContext, Context } from 'react';

import { AppProvider, AppCtx } from './App/Provider';
import { CartProvider, CartCtx } from './Cart/Provider';
import { CustomerProvider, CustomerCtx } from './Customer/Provider';
import { LangProvider, LangCtx } from './Lang/Provider';
import { WindowProvider, WindowCtx } from './Window/Provider';
import { WithState, WithDispatch, ElasticObject } from './index.d';

const useActions = <T extends WithDispatch>(
    ctx: Context<T>,
    selected: string | string[],
) => {
    const { dispatch } = useContext<T>(ctx);

    if (Array.isArray(selected)) {
        return selected.reduce(
            (actions: ElasticObject, name: string) => ({
                ...actions,
                [name]: (payload: any) =>
                    dispatch({
                        type: name,
                        payload,
                    }),
            }),
            {},
        );
    }
    return {
        [selected]: (payload: any) =>
            dispatch({
                type: selected,
                payload,
            }),
    };
};

const useContextState = <T extends WithState>(
    ctx: Context<T>,
    selected: string | string[] = '*',
) => {
    const { state } = useContext<T>(ctx);
    if (Array.isArray(selected)) {
        return selected.reduce(
            (states: ElasticObject, name: string) => ({
                ...states,
                [name]: state[name],
            }),
            {},
        );
    }
    return selected === '*'
        ? state
        : {
              [selected]: state[selected],
          };
};

export {
    useActions,
    useContextState,
    CartProvider,
    CartCtx,
    CustomerProvider,
    CustomerCtx,
    AppProvider,
    AppCtx,
    WindowProvider,
    WindowCtx,
    LangProvider,
    LangCtx,
};
