import React, { useReducer, useEffect, useLayoutEffect } from 'react';

import AppCtx from './ctx';
import reducer from './reducer';
import initialState from './state';
import type { AppProviderProps } from './types';

const AppProvider: React.FC<AppProviderProps> = ({
    children,
    onLoad = () => false,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useLayoutEffect(() => {
        onLoad(dispatch);
    }, []);

    useEffect(() => {
        console.log('AppCtx state ', state);
        localStorage.setItem('AppCtx', JSON.stringify(state));
        // onStateChange(state)
    }, [state]);

    return (
        <AppCtx.Provider
            value={{
                state: { ...state },
                dispatch,
            }}>
            {children}
        </AppCtx.Provider>
    );
};

export { AppProvider, AppCtx };
