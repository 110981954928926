import type {
    ICartState,
    ItemInterface,
    IShipping,
    IShippingRules,
} from './types';
import { getProperShippingsList } from './utils';

class CartActions {
    addToCart = (
        state: ICartState,
        { payload }: { payload: ItemInterface },
    ) => {
        const item = { ...payload };

        const productIndex = state.items.findIndex(
            ({ product_id }) => item.product_id === product_id,
        );

        const { quantity, sale, regularPrice, price } = item;

        const items =
            productIndex === -1
                ? [...state.items, item]
                : [...state.items].map((item, index) => {
                      if (index === productIndex) {
                          const additionalItemValue = sale
                              ? quantity * price
                              : quantity * regularPrice;
                          return {
                              ...item,
                              quantity: quantity + item.quantity,
                              value: item.value + additionalItemValue,
                              regularValue:
                                  item.regularValue + quantity * regularPrice,
                          };
                      }
                      return item;
                  });

        const val = items.reduce((acc, cur) => acc + cur.value, 0);
        const qty = items.reduce((acc, cur) => acc + cur.quantity, 0);

        return {
            ...state,
            items,
            sum: { val, qty },
            shippings: getProperShippingsList(state.shippingRules, val),
        };
    };

    removeFromCart = (
        state: ICartState,
        { payload }: { payload: ItemInterface },
    ) => {
        const item = { ...payload };
        const { product_id } = item;
        const newItems = [...state.items].filter(
            (el) => el.product_id !== product_id,
        );

        const val = newItems.reduce((acc, cur) => acc + cur.value, 0);
        const qty = newItems.reduce((acc, cur) => acc + cur.quantity, 0);

        return {
            ...state,
            items: newItems,
            sum: { val, qty },
            shippings: getProperShippingsList(state.shippingRules, val),
        };
    };

    changeProductQty = (
        state: ICartState,
        { payload }: { payload: ItemInterface },
    ) => {
        const item = { ...payload };
        const { product_id, quantity } = item;
        console.log('change', product_id);

        if (quantity <= 0) return { ...state };

        const newItems = [...state.items].map((el) => {
            if (el.product_id == product_id) {
                el.quantity = quantity;
                el.regularValue = quantity * el.regularPrice;
                if (el.sale) {
                    el.value = quantity * el.price;
                } else {
                    el.value = quantity * el.regularPrice;
                }
            }
            return el;
        });

        const val = newItems.reduce((acc, cur) => acc + cur.value, 0);
        const qty = newItems.reduce((acc, cur) => acc + cur.quantity, 0);

        return {
            ...state,
            items: newItems,
            sum: { val, qty },
            shippings: getProperShippingsList(state.shippingRules, val),
        };
    };

    setShippings = (
        state: ICartState,
        { payload }: { payload: IShippingRules },
    ) => {
        console.log('setShippingPayload: ', payload);
        const action = { ...payload };

        const val = state.items.reduce((acc, cur) => acc + cur.value, 0);

        return {
            ...state,
            shippingRules: action,
            shippings: getProperShippingsList(action, val),
        };
    };

    setShipping = (state: ICartState, { payload }: { payload: IShipping }) => {
        return {
            ...state,
            shipping: { ...payload },
        };
    };

    setCoupon = (state: ICartState, { payload }: { payload: string }) => {
        return {
            ...state,
            coupon: payload,
        };
    };

    setOrderFromApi = (
        state: ICartState,
        { payload }: { payload: Record<string, any> },
    ) => {
        console.log('setOrderFromApiPayload: ' + payload);

        return {
            ...state,
            imBusy: false,
            error: null,
            orderInApi: payload,
        };
    };

    onOrderApiError = (state: ICartState, { payload }: { payload: any }) => {
        const action = { ...payload };

        return {
            ...state,
            imBusy: false,
            error: action,
        };
    };

    loadCart = (
        state: ICartState,
        { payload: { cart } }: { payload: { cart: ICartState } },
    ) => {
        const { items } = cart;
        const val = items.reduce((acc, cur) => acc + cur.value, 0);
        const qty = items.reduce((acc, cur) => acc + cur.quantity, 0);
        return {
            ...state,
            items: items,
            sum: { val, qty },
        };
    };

    resetCart = (state: ICartState) => {
        return {
            ...state,
            imBusy: false,
            items: [],
            shippings: getProperShippingsList(state.shippingRules, 0),
            orderInApi: {},
            // shipping: {},
            coupon: '',
            sum: { val: 0, qty: 0 },
        };
    };

    changePaymentStatus = (
        state: ICartState,
        { payload }: { payload: Record<string, string> },
    ) => {
        return {
            ...state,
            payment: payload,
        };
    };
}

const actions = new CartActions();
export default actions;
