import React, { useReducer, useEffect, useLayoutEffect } from 'react';

import LangCtx from './ctx';
import reducer from './reducer';
import initialState from './state';
import type { LangProviderProps } from './types';

const LangProvider: React.FC<LangProviderProps> = ({
    children,
    onLoad = () => false,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useLayoutEffect(() => {
        onLoad(dispatch);
    }, []);

    useEffect(() => {
        console.log('LangCtx state', state);
    }, [state]);

    return (
        <LangCtx.Provider
            value={{
                state: { ...state },
                dispatch,
            }}>
            {children}
        </LangCtx.Provider>
    );
};

export { LangProvider, LangCtx };
