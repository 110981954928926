import actions from './actions';
import initialState from './state';
import type { AppActionType, IAppState } from './types';

function appReducer(state = initialState, action: AppActionType): IAppState {
    switch (action.type) {
        case 'loaded':
            return actions.loaded();
        case 'loading':
            return actions.loading();
        default:
            throw new Error('Wrong action type in app reducer');
    }
}

export default appReducer;
