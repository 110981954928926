import React from 'react';

import initialState from './state';
import type { ICartContext } from './types';

const CartCtx = React.createContext<ICartContext>({
    state: { ...initialState },
    dispatch: () => null,
});

export default CartCtx;
