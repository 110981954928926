// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog/post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-standard-contact-tsx": () => import("./../../../src/templates/standard/contact.tsx" /* webpackChunkName: "component---src-templates-standard-contact-tsx" */),
  "component---src-templates-standard-home-tsx": () => import("./../../../src/templates/standard/home.tsx" /* webpackChunkName: "component---src-templates-standard-home-tsx" */),
  "component---src-templates-standard-informations-tsx": () => import("./../../../src/templates/standard/informations.tsx" /* webpackChunkName: "component---src-templates-standard-informations-tsx" */),
  "component---src-templates-standard-research-tsx": () => import("./../../../src/templates/standard/research.tsx" /* webpackChunkName: "component---src-templates-standard-research-tsx" */)
}

