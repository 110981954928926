class AppActions {
  loaded = () => {
    return {
      imBusy: false,
    }
  }

  loading = () => {
    return {
      imBusy: true,
    }
  }
}

const actions = new AppActions()
export default actions