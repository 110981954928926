import actions from './actions';
import initialState from './state';
import type { ICustomerState, CustomerActionType } from './types';

function customerReducer(
    state = initialState,
    action: CustomerActionType,
): ICustomerState {
    switch (action.type) {
        case 'changeValue':
            return actions.changeValue(state, action);
        case 'loadCustomer':
            return actions.loadCustomer(state, action);
        default:
            throw new Error('Wrong action type in customer reducer');
    }
}

export default customerReducer;
