import React from 'react';

import initialState from './state';
import type { ILangContext } from './types';

const LangCtx = React.createContext<ILangContext>({
    state: { ...initialState },
    dispatch: () => null,
});

export default LangCtx;
