import actions from './actions';
import initialState from './state';
import type { CartActionType, ICartState } from './types';

function cartReducer(state = initialState, action: CartActionType): ICartState {
    switch (action.type) {
        case 'addToCart':
            return actions.addToCart(state, action);
        case 'removeFromCart':
            return actions.removeFromCart(state, action);
        case 'changeProductQty':
            return actions.changeProductQty(state, action);
        case 'setShippings':
            return actions.setShippings(state, action);
        case 'setShipping':
            return actions.setShipping(state, action);
        case 'setCoupon':
            return actions.setCoupon(state, action);
        case 'loadCart':
            return actions.loadCart(state, action);
        case 'resetCart':
            return actions.resetCart(state);
        case 'setOrderFromApi':
            return actions.setOrderFromApi(state, action);
        case 'onOrderApiError':
            return actions.onOrderApiError(state, action);
        case 'changePaymentStatus':
            return actions.changePaymentStatus(state, action);
        default:
            throw new Error('Wrong action type in cart reducer');
    }
}

export default cartReducer;
