import actions from './actions';
import initialState from './state';
import type { ILangState, LangActionType } from './types';

const appReducer = (
    state = initialState,
    action: LangActionType,
): ILangState => {
    switch (action.type) {
        case 'setDefaultLang':
            return actions.setDefaultLang(state, action);
        case 'setLangPl':
            return actions.setLangPl();
        case 'setLangEn':
            return actions.setLangEn();
        default:
            throw new Error('Wrong action type in window reducer');
    }
};

export default appReducer;
