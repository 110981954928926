import React, {
    useReducer,
    useCallback,
    useEffect,
    useLayoutEffect,
} from 'react';

import CustomerCtx from './ctx';
import reducer from './reducer';
import initialState from './state';
import type { CustomerProviderProps } from './types';

const CustomerProvider: React.FC<CustomerProviderProps> = ({
    children,
    onLoad = () => false,
    onStateChange = () => false,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useLayoutEffect(() => {
        onLoad(dispatch);
        const customer = localStorage.getItem('CustomerCtx');
        if (customer) {
            dispatch({
                type: 'loadCustomer',
                payload: {
                    customer: JSON.parse(customer),
                },
            });
        }
    }, []);

    useEffect(() => {
        console.log('CustomerProvider state ', state);
        onStateChange(state);
        localStorage.setItem('CustomerCtx', JSON.stringify(state));
    }, [state]);

    return (
        <CustomerCtx.Provider
            value={{
                state: { ...state },
                dispatch,
            }}>
            {children}
        </CustomerCtx.Provider>
    );
};

export { CustomerProvider, CustomerCtx };
