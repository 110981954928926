import React from 'react';

import initialState from './state';
import type { IWindowState } from './types';

const WindowCtx = React.createContext<IWindowState>({
    ...initialState,
});

export default WindowCtx;
