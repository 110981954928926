import type { ILangState } from './types';

class LangActions {
    setDefaultLang = (
        state: ILangState,
        { payload }: { payload: ILangState },
    ) => {
        console.log('lll')
        const { language } = payload;
        return {
            ...state,
            language: language,
        };
    };

    setLangPl = () => {
        return {
            language: 'pl',
        };
    };

    setLangEn = () => {
        return {
            language: 'en',
        };
    };
}

const actions = new LangActions();
export default actions;
