import type {
    ICustomerState,
    ChangeValuePayloadType,
    LoadCustomerPayloadType,
} from './types';

class CustomerActions {
    changeValue = (
        state: ICustomerState,
        { payload }: { payload: ChangeValuePayloadType },
    ) => {
        return {
            ...state,
            ...payload,
        };
    };

    loadCustomer = (
        state: ICustomerState,
        { payload }: { payload: LoadCustomerPayloadType },
    ) => {
        const { customer } = payload;
        return {
            ...state,
            ...customer,
        };
    };
}

const actions = new CustomerActions();
export default actions;
