import type { IShippingRules } from './types';

function getProperShippingsList(
    shippingsRules: IShippingRules = {},
    value: number,
) {
    if (Object.keys(shippingsRules).length === 0) {
        return [];
    }

    const [rangeValue] = Object.keys(shippingsRules).filter(
        (el: any) => el !== 'always' || el !== 'free',
    );

    if (Number(rangeValue) < value) {
        return [...shippingsRules[rangeValue], ...shippingsRules['free']];
    } else {
        return [...shippingsRules['always'], ...shippingsRules['free']];
    }
}

export { getProperShippingsList };
