import {
    CustomerProvider,
    LangProvider,
    AppProvider,
    WindowProvider,
    WindowCtx,
    useActions,
    AppCtx,
} from '@components/contexted';
import React, { useCallback } from 'react';

import Loader from './InitLoading';

interface ProviderProps {
    children: React.ReactNode;
}

const onAppInit = (dispatch) => {};

const onInitSearchData = (appLoaded) => (dispatch) => {
    appLoaded();
};

const OuterProviders: React.FC<ProviderProps> = ({ children }) => {
    return (
        <WindowProvider>
            <AppProvider onLoad={onAppInit}>{children}</AppProvider>
        </WindowProvider>
    );
};

const InnerProviders: React.FC<ProviderProps> = ({ children }) => {
    const { loaded } = useActions(AppCtx, 'loaded');
    const onLoadSearchData = useCallback(onInitSearchData(loaded), []);
    let lang = "pl"
    if (typeof window !== "undefined") {
        let storageLang = localStorage.getItem("lang")
        if (storageLang) {
        lang = storageLang
        }
    }
    const onInitLanguage = (dispatch) => {
        console.log("initLanguage")
            dispatch({
            type: "setDefaultLang",
            payload: {
                language: lang,
            },
        })
    }
    return (
        <CustomerProvider onLoad={onLoadSearchData}>
            <LangProvider onLoad={onInitLanguage}>
                <Loader />
                {children}
            </LangProvider>
        </CustomerProvider>
    );
};

export default ({ element }: { element: React.ReactNode }) => {
    return (
        <OuterProviders>
            <InnerProviders>{element}</InnerProviders>
        </OuterProviders>
    );
};

export { WindowCtx };
